var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"mb":"8"}},[_c('c-flex',{attrs:{"p":"6","bg":"#fff","borderRadius":"8px","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","h":"100px"}},[(_vm.isCompanyAdmin)?_c('c-popover',{attrs:{"placement":'bottom'},scopedSlots:_vm._u([{key:"default",fn:function({ onClose }){return [_c('c-popover-trigger',[_c('c-flex',{attrs:{"align":"center","mr":"6","cursor":"pointer"}},[_c('c-avatar',{attrs:{"size":"md","name":_vm.postAs
                ? _vm.activeCompany.name
                : _vm.user.firstname + ' ' + _vm.user.lastname,"src":_vm.postAs ? _vm.activeCompany.logo : _vm.user.profilePhoto,"borderWidth":"0px","mr":"2"}}),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              fill: 'vc-orange.400',
              w: '14px',
              h: '14px',
            }),expression:"{\n              fill: 'vc-orange.400',\n              w: '14px',\n              h: '14px',\n            }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/chevron-down.svg") + "#chevron"}})])],1)],1),_c('c-popover-content',{attrs:{"width":"max-content"}},[_c('c-popover-body',{attrs:{"padding":"0"}},[_c('c-list',[_c('c-list-item',[(!_vm.postAs)?_c('c-pseudo-box',{attrs:{"alignItems":"center","px":"5","py":"3","display":"flex","cursor":"pointer","_hover":{
                  bg: 'gray.300',
                }},on:{"click":function($event){return _vm.togglePostAs(_vm.activeCompany.id, onClose)}}},[_c('c-text',[_vm._v("Post as "+_vm._s(_vm.activeCompany.name))])],1):_c('c-pseudo-box',{attrs:{"alignItems":"center","px":"5","py":"3","display":"flex","cursor":"pointer","_hover":{
                  bg: 'gray.300',
                }},on:{"click":function($event){return _vm.togglePostAs(null, onClose)}}},[_c('c-text',[_vm._v(" Post as "+_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname)+" ")])],1)],1)],1)],1)],1)]}}],null,false,1567492658)}):_c('c-flex',{attrs:{"align":"center","mr":"6"}},[_c('c-avatar',{attrs:{"size":"md","name":_vm.user.firstname + ' ' + _vm.user.lastname,"src":_vm.user.profilePhoto,"borderWidth":"0px"}})],1),_c('c-pseudo-box',{attrs:{"alignItems":"center","bg":"gray.200","borderRadius":"100px","px":"7","flex":"1","display":"flex","cursor":"pointer","_hover":{
        bg: 'gray.300',
      }},on:{"click":_vm.openPostModal}},[_c('c-text',{attrs:{"fontSize":"lg","color":"grar.400","fontWeight":"500"}},[_vm._v(" Start a post ")])],1)],1),_c('c-modal',{attrs:{"size":"xl","is-open":_vm.isPostModalOpen,"on-close":_vm.closePostModal}},[_c('c-modal-content',{ref:"content",attrs:{"borderRadius":"10px"}},[_c('c-modal-header',[_vm._v("Create a post")]),_c('c-modal-close-button'),_c('c-modal-body',[_c('c-flex',{attrs:{"direction":"column","w":"100%","h":"200px","mb":"3","borderRadius":"10px","bg":"gray.200"}},[_c('c-box',{attrs:{"maxH":"180px","overflowY":"auto"}},[_c('quill-editor',{ref:"textEditor",staticClass:"editor",attrs:{"options":_vm.editorOptions},on:{"change":_vm.onChange},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('c-flex',{attrs:{"mt":"auto","px":"2"}},[_c('input',{ref:"fileInput",attrs:{"type":"file","hidden":"","name":"","id":"","accept":_vm.fileUploadType + '/*'},on:{"change":_vm.onFileUpload}}),_c('c-button',{attrs:{"px":"0","variant-color":"blue","variant":"ghost","display":"flex","alignItems":"center","justifyContent":"center","disabled":_vm.isCreatingPost},on:{"click":_vm.addImage}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '20px',
                  h: '20px',
                  fill: '#000',
                }),expression:"{\n                  w: '20px',\n                  h: '20px',\n                  fill: '#000',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/image.svg") + "#image"}})])]),_c('c-button',{attrs:{"px":"0","variant-color":"blue","variant":"ghost","display":"flex","alignItems":"center","disabled":_vm.isCreatingPost,"justifyContent":"center"},on:{"click":_vm.addVideo}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '20px',
                  h: '20px',
                  fill: '#000',
                }),expression:"{\n                  w: '20px',\n                  h: '20px',\n                  fill: '#000',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/video.svg") + "#video"}})])])],1)],1),_c('c-flex',{attrs:{"my":"2"}},_vm._l((_vm.uploadedImages),function(image){return _c('c-box',{key:image.id,attrs:{"borderRadius":"6px","borderWidth":"1px","w":"90px","h":"90px","overflow":"hidden","mr":"2","position":"relative"}},[(image.isUploading)?_c('c-flex',{attrs:{"top":"0","left":"0","right":"0","bottom":"0","align":"center","zIndex":"docked","justify":"center","position":"absolute","borderRadius":"inherit","backgroundImage":"linear-gradient(\n                  to right top,\n                  rgba(0, 0, 0, 0.7),\n                  rgba(0, 0, 0, 0.7)\n              )"}},[_c('c-spinner',{attrs:{"color":"#fff","thickness":"2px"}})],1):_vm._e(),(image.failed)?_c('c-flex',{attrs:{"top":"0","left":"0","right":"0","bottom":"0","align":"center","zIndex":"docked","justify":"center","position":"absolute","borderRadius":"inherit","backgroundImage":"linear-gradient(\n                  to right top,\n                  rgba(0, 0, 0, 0.7),\n                  rgba(0, 0, 0, 0.7)\n              )"}},[_c('c-button',{attrs:{"px":"0","variant-color":"blue","variant":"ghost","display":"flex","alignItems":"center","disabled":_vm.isCreatingPost,"justifyContent":"center"},on:{"click":function($event){return _vm.uploadFile({
                    file: image.file,
                    type: image.type,
                    id: image.id,
                  })}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    w: '20px',
                    h: '20px',
                    fill: '#fff',
                  }),expression:"{\n                    w: '20px',\n                    h: '20px',\n                    fill: '#fff',\n                  }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-upload.svg") + "#upload"}})])])],1):_vm._e(),_c('img',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '100%',
                h: '100%',
                objectFit: 'cover',
                objectPosition: 'top center',
              }),expression:"{\n                w: '100%',\n                h: '100%',\n                objectFit: 'cover',\n                objectPosition: 'top center',\n              }"}],attrs:{"src":image.url,"alt":"","srcset":""}})],1)}),1),_c('c-flex',{attrs:{"my":"2"}},_vm._l((_vm.uploadedVideos),function(video){return _c('c-flex',{key:video.id,attrs:{"mr":"2","p":"2","h":"90px","w":"190px","borderRadius":"6px","borderWidth":"1px","overflow":"hidden","direction":"column","align":"center","textAlign":"center","position":"relative"}},[(video.isUploading)?_c('c-flex',{attrs:{"top":"0","left":"0","right":"0","bottom":"0","align":"center","zIndex":"docked","justify":"center","position":"absolute","borderRadius":"inherit","backgroundImage":"linear-gradient(\n                  to right top,\n                  rgba(0, 0, 0, 0.7),\n                  rgba(0, 0, 0, 0.7)\n              )"}},[_c('c-spinner',{attrs:{"color":"#fff","thickness":"2px"}})],1):_vm._e(),(video.failed)?_c('c-flex',{attrs:{"top":"0","left":"0","right":"0","bottom":"0","align":"center","zIndex":"docked","justify":"center","position":"absolute","borderRadius":"inherit","backgroundImage":"linear-gradient(\n                  to right top,\n                  rgba(0, 0, 0, 0.7),\n                  rgba(0, 0, 0, 0.7)\n              )"}},[_c('c-button',{attrs:{"px":"0","variant-color":"blue","variant":"ghost","display":"flex","alignItems":"center","justifyContent":"center"},on:{"click":function($event){return _vm.uploadFile({
                    file: video.file,
                    type: video.type,
                    id: video.id,
                  })}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    w: '20px',
                    h: '20px',
                    fill: '#fff',
                  }),expression:"{\n                    w: '20px',\n                    h: '20px',\n                    fill: '#fff',\n                  }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-upload.svg") + "#upload"}})])])],1):_vm._e(),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                w: '40px',
                h: '40px',
                fill: '#000',
              }),expression:"{\n                w: '40px',\n                h: '40px',\n                fill: '#000',\n              }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/video.svg") + "#video"}})]),_c('c-text',{attrs:{"fontSize":"sm","fontWeight":"600"}},[_vm._v(_vm._s(video.name))])],1)}),1)],1),_c('c-modal-footer',{attrs:{"pt":"0"}},[_c('c-button',{attrs:{"disabled":_vm.isContentEmpty || _vm.isCreatingPost,"variant-color":"vc-orange","variant":"outline","mr":"3"},on:{"click":_vm.onPostClick}},[_vm._v(" Post "),(_vm.isCreatingPost)?_c('c-spinner',{attrs:{"color":"vc-orange.400","thickness":"2px"}}):_vm._e()],1)],1)],1),_c('c-modal-overlay')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }