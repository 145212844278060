<template>
  <c-box h="100%" py="8" :px="{ base: '6', lg: 0 }">
    <c-flex align="center" justify="space-between" mb="8">
      <c-flex align="baseline">
        <c-heading fontSize="3xl" fontWeight="700" mr="3"> Explore </c-heading>
      </c-flex>
      <Notification />
    </c-flex>
    <c-grid
      w="100%"
      :h="{ base: 'calc(130% - 20px)', lg: 'calc(100% - 70px)' }"
      :template-columns="{
        base: '1fr',
        lg: '70% 1fr',
        md: '1fr',
        sm: '1fr',
        xs: '1fr',
      }"
      gap="8"
    >
      <c-stack
        pb="3"
        px="2"
        :spacing="8"
        maxHeight="100%"
        overflowY="scroll"
        @scroll.native="handleScroll"
      >
        <NewPost @postAdded="onPostAdded" />
        <!-- <c-flex
          h="100px"
          px="5"
          justify="space-between"
          align="center"
          bg="#fff"
          borderRadius="8px"
          boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
        >
          <c-input-group>
            <c-input-left-element>
              <svg
                v-chakra="{
                  w: '15px',
                  h: '15px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/icon-search.svg#icon-search"></use>
              </svg>
            </c-input-left-element>
            <c-input
              borderColor="#798892"
              borderTopWidth="0"
              borderRightWidth="0"
              borderLeftWidth="0"
              borderRadius="0"
              type="text"
              placeholder="Search Posts"
            />
          </c-input-group>
          <AppSelect
            :options="['All Posts']"
            :value="'All Posts'"
            :indicatorFill="'orange'"
            :color="'dark'"
            :size="'large'"
          />
        </c-flex> -->
        <c-text v-if="!isFetchingPosts && !posts.length" fontStyle="italic">
          You have no posts on your timeline
        </c-text>
        <template v-for="post in posts">
          <c-box :key="post.id">
            <NewsCard
              @togglePostSave="onPostSaveToggle"
              @selectPostAttachment="
                selectedPost = $event;
                isImagePreviewOpen = true;
              "
              @deletePost="onDeletePost(post)"
              :post="post"
            />
          </c-box>
        </template>
        <image-preview
          @setIsOpen="isImagePreviewOpen = $event"
          :isOpen="isImagePreviewOpen"
          :post="selectedPost"
          :attachments="
            selectedPost.attachments.map((attachment) => attachment.url)
          "
        />
        <c-flex justify="center">
          <c-spinner
            v-if="isFetchingPosts || isFetchingMorePosts"
            color="blue.500"
            thickness="2px"
            size="xl"
            mx="auto"
          />
        </c-flex>
      </c-stack>
      <c-stack :spacing="4" pb="3">
        <c-box>
          <SuggestTags />
        </c-box>
        <SavedPosts
          @togglePostSave="onPostSaveToggle"
          :savedPosts="savedPosts"
        />
      </c-stack>
    </c-grid>
    <c-alert-dialog
      :is-open="isDeleteDialogOpen"
      :least-destructive-ref="$refs.cancelRef"
      :on-close="closeDeleteDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Delete Post
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Are you sure? You can't undo this action afterwards.
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeDeleteDialog">
            Cancel
          </c-button>
          <c-button variantColor="red" @click="deletePost" ml="3">
            Delete
            <c-spinner
              ml="3"
              v-if="isDeletingPost"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import {
  addPostSave,
  deletePostSave,
  getPosts,
  updatePost,
} from '@/services/insight';
import { generateID } from '@/helpers/data';

import cloneDeep from 'lodash.clonedeep';

// import AppSelect from '../components/AppSelect.vue';
import NewPost from './components/NewPost.vue';
import NewsCard from './components/NewsCard.vue';
import SavedPosts from './components/SavedPosts.vue';
import SuggestTags from './components/SuggestTags.vue';
import Notification from '../components/Notification.vue';
import ImagePreview from './components/ImagePreview.vue';

export default {
  components: {
    NewsCard,
    NewPost,
    SavedPosts,
    SuggestTags,
    Notification,
    ImagePreview,
  },
  data() {
    return {
      posts: [],
      savedPosts: [],
      postsOffset: 0,
      postsLimit: 5,
      totalPosts: 0,
      isFetchingPosts: false,
      isFetchingMorePosts: false,
      isDeleteDialogOpen: false,
      isImagePreviewOpen: false,
      postToDelete: null,
      isDeletingPost: false,
      selectedPost: {
        attachments: [],
      },
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getPosts();
  },
  methods: {
    onPostAdded(post) {
      this.posts.unshift({ ...post });
    },
    getPosts() {
      this.isFetchingPosts = true;
      getPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = res.data.post;
        this.savedPosts = res.data.post_save;
        this.totalPosts = res.data.post_aggregate.aggregate.count;
        this.isFetchingPosts = false;
      });
    },
    async onPostSaveToggle(post) {
      const data = {
        postId: post.id,
        userId: this.user.id,
      };
      const postIndex = this.posts.findIndex((_post) => post.id === _post.id);
      if (post.post_saves.length) {
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.post.id === post.id
        );
        const savedPost = cloneDeep(this.savedPosts[savedPostIndex]);
        this.posts[postIndex].post_saves.pop();
        this.savedPosts.splice(savedPostIndex, 1);
        try {
          await deletePostSave(savedPost.id);
        } catch (e) {
          this.savedPosts.splice(savedPostIndex, 0, { ...savedPost });
          this.posts[postIndex].post_saves.push({ ...data });
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't remove saved post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        this.posts[postIndex].post_saves.push({ ...data });
        const genId = generateID(4);
        this.savedPosts.push({ id: genId, post: { ...post } });
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.id === genId
        );
        try {
          const res = await addPostSave(data);
          const { id } = res.data.insert_post_save_one;
          this.savedPosts[savedPostIndex].id = id;
        } catch (e) {
          const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.post.id === post.id
          );
          this.savedPosts.splice(savedPostIndex, 1);
          this.posts[postIndex].post_saves.pop();
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't save post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    handleScroll(e) {
      const element = e.target;
      if (
        Math.ceil(element.scrollHeight - element.scrollTop) ===
        element.clientHeight
      ) {
        if (
          !this.isFetchingMorePosts &&
          this.totalPosts !== this.posts.length
        ) {
          this.getMorePosts();
        }
      }
    },
    getMorePosts() {
      this.isFetchingMorePosts = true;
      this.postsOffset = this.postsOffset + this.postsLimit;
      getPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = [...cloneDeep(this.posts), ...res.data.post];
        this.isFetchingMorePosts = false;
      });
    },
    onDeletePost(post) {
      this.isDeleteDialogOpen = true;
      this.postToDelete = { ...post };
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.postToDelete = null;
    },
    async deletePost() {
      this.isDeletingPost = true;
      try {
        await updatePost({
          id: this.postToDelete.id,
          set: { isDeleted: true },
        });
        const postIndex = this.posts.findIndex(
          (post) => post.id === this.postToDelete.id
        );
        this.posts.splice(postIndex, 1);
        this.closeDeleteDialog();
        this.$toast({
          title: 'Success!!!',
          description: `Post has been deleted`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Could not delete post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
