<template>
  <c-box w="100%" h="100%" :px="{ base: '6', lg: 0 }">
<!--    <c-flex align="center" justify="space-between" mb="8">-->
<!--      <c-flex align="baseline">-->
<!--        <c-heading fontSize="3xl" fontWeight="700" mr="3">-->
<!--          View Post-->
<!--        </c-heading>-->
<!--      </c-flex>-->
<!--      <Notification />-->
<!--    </c-flex>-->
    <new-post v-show="false" :edited-post="postEdited" :open-modal="openPostModal" @postAdded="onPostAdded" />
    <c-box>
      <c-text v-if="!isFetchingPost && !posts.length" fontStyle="italic">
        You have no posts on your timeline
      </c-text>
      <template v-for="(post, index) in posts">
        <c-box :key="post.id">
          <PostCard
              @post-deleted="removeDeletedPost(index)"
              @togglePostSave="onPostSaveToggle"
              @selectPostAttachment="
              selectedPost = $event;
              isImagePreviewOpen = true;
            "
              @deletePost="onDeletePost(post)"
              :is-post-detail-page="true"
              :post="post"
          />
        </c-box>
      </template>
<!--      <template v-for="post in posts">-->
<!--        <c-box :key="post.id">-->
<!--          <NewsCard-->
<!--            @togglePostSave="onPostSaveToggle"-->
<!--            @selectPostAttachment="-->
<!--              selectedPost = $event;-->
<!--              isImagePreviewOpen = true;-->
<!--            "-->
<!--            @deletePost="onDeletePost(post)"-->
<!--            :post="post"-->
<!--          />-->
<!--        </c-box>-->
<!--      </template>-->
    </c-box>
  </c-box>
</template>

<script>
// import Notification from '../components/Notification.vue';
// import NewsCard from './components/NewsCard.vue';
import PostCard from "@/views/App/Explore/components/PostCard.vue";
import { mapState } from 'vuex';
import NewPost from "@/views/App/Explore/components/NewPost.vue";
import {
  getPost,
  addPostSave,
  deletePostSave,
} from '../../../services/insight';
import { generateID } from '../../../helpers/data';
import cloneDeep from 'lodash.clonedeep';

export default {
  components: {
    // Notification,
    // NewsCard,
    PostCard,
    NewPost
  },
  data() {
    return {
      isFetchingPost: true,
      posts: [],
      selectedPost: {
        attachments: [],
      },
      isImagePreviewOpen: false,
      isDeleteDialogOpen: false,
      postToDelete: null,
      postEdited: null,
      openPostModal: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getPost();
  },
  methods: {
    onPostAdded(post) {
      this.posts.unshift({ ...post });
    },
    onDeletePost(post) {
      this.isDeleteDialogOpen = true;
      this.postToDelete = { ...post };
    },
    removeDeletedPost(deletedIndex){
      if( deletedIndex > -1 ){
        this.posts.splice( deletedIndex,1 )
      }
    },
    getPost() {
      const postId = this.$route.params.id;
      this.isFetchingPost = true;
      getPost({
        id: postId,
        userId: this.user.id,
      }).then((res) => {
        console.log('view post', res.data);
        this.posts = res.data.post;
        this.isFetchingPost = false;
      });
    },
    async onPostSaveToggle(post) {
      const data = {
        postId: post.id,
        userId: this.user.id,
      };
      const postIndex = this.posts.findIndex((_post) => post.id === _post.id);
      if (post.post_saves.length) {
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.post.id === post.id
        );
        const savedPost = cloneDeep(this.savedPosts[savedPostIndex]);
        this.posts[postIndex].post_saves.pop();
        this.savedPosts.splice(savedPostIndex, 1);
        try {
          await deletePostSave(savedPost.id);
        } catch (e) {
          this.savedPosts.splice(savedPostIndex, 0, { ...savedPost });
          this.posts[postIndex].post_saves.push({ ...data });
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't remove saved post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        this.posts[postIndex].post_saves.push({ ...data });
        const genId = generateID(4);
        this.savedPosts.push({ id: genId, post: { ...post } });
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.id === genId
        );
        try {
          const res = await addPostSave(data);
          const { id } = res.data.insert_post_save_one;
          this.savedPosts[savedPostIndex].id = id;
        } catch (e) {
          const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.post.id === post.id
          );
          this.savedPosts.splice(savedPostIndex, 1);
          this.posts[postIndex].post_saves.pop();
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't save post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>
