<template>
  <c-modal
    overflowY="hidden"
    size="full"
    :is-open="isOpen"
    :on-close="emitClose"
  >
    <c-modal-overlay />
    <c-modal-content
      class="h-screen"
      bg="transparent"
      ref="content"
      overflowY="hidden"
      boxShadow="0"
      p="0"
      m="0"
      borderRadius="10px"
    >
      <c-modal-close-button />
      <c-modal-body mx="0" px="0" bg="transparent" overflowY="hidden">
        <c-flex class="" bg="transparent">
          <c-box
            w="70%"
            h="100vh"
            py="10"
            class="d-flex justify-center items-center"
          >
            <c-flex align="center" justify="center">
              <c-icon-button
                @click="back"
                mr="4"
                ariaLabel="arrow-left"
                aria-label="Previous Image"
                icon="arrow-left"
              />
              <img
                v-chakra="{
                  w: '800px',
                  h: '900px',
                  objectFit: 'contain',
                  objectPosition: 'center center',
                }"
                :src="attachments[index]"
                alt=""
                srcset=""
              />
              <c-icon-button
                @click="next"
                ml="4"
                ariaLabel="arrow-right"
                aria-label="Next Image"
                icon="arrow-right"
              />
            </c-flex>
          </c-box>
          <c-box p="5" bg="white" w="30%" h="100vh">
            <NewsCard previewModal :post="post" />
          </c-box>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
  </c-modal>
</template>

<script>
import NewsCard from './NewsCard.vue';
export default {
  components: {
    NewsCard,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    attachments: {
      type: Array,
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      index: this.initialIndex,
    };
  },
  methods: {
    next() {
      if (this.index === this.attachments.length - 1) {
        this.index = 0;
      } else if (this.index < this.attachments.length - 1) {
        this.index++;
      }
    },
    back() {
      if (this.index === 0) {
        this.index = this.attachments.length - 1;
      } else if (this.index > 0 && this.index < this.attachments.length) {
        this.index--;
      }
    },
    emitClose() {
      this.$emit('setIsOpen', false);
    },
  },
};
</script>
