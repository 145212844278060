import { render, staticRenderFns } from "./ImagePreview.vue?vue&type=template&id=ac56c48c"
import script from "./ImagePreview.vue?vue&type=script&lang=js"
export * from "./ImagePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CIconButton: require('@chakra-ui/vue').CIconButton, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal})
