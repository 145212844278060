<template>
  <c-box
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
  >
    <c-flex
      borderBottomWidth="1px"
      h="60px"
      justify="space-between"
      align="center"
      px="6"
    >
      <c-text fontWeight="600" fontSize="lg"> My Saves </c-text>
      <!-- <AppSelect
              :options="['All Categories']"
              :value="'All Categories'"
              :indicatorFill="'orange'"
              :color="'dark'"
            /> -->
    </c-flex>
    <c-text
      v-if="!savedPosts.length"
      px="2"
      fontSize="sm"
      py="3"
      fontStyle="italic"
    >
      You don't have any saved posts
    </c-text>
    <template v-for="(savedPost, index) in savedPosts.slice(0, 3)">
      <c-box
        :borderBottomWidth="index !== savedPosts.length - 1 ? '1px' : ''"
        px="6"
        py="4"
        display="flex"
        alignItems="start"
        :key="savedPost.id"
        @click="onPostClick(savedPost)"
        cursor="pointer"
      >
        <c-avatar
          size="md"
          :name="
            savedPost.post.companyId
              ? savedPost.post.company.name
              : savedPost.post.user.firstname +
                ' ' +
                savedPost.post.user.lastname
          "
          :src="
            savedPost.post.companyId
              ? savedPost.post.company.logo
              : savedPost.post.user.profilePhoto
          "
          borderWidth="0px"
          mr="2"
        />
        <c-box flex="1">
          <c-flex justify="space-between">
            <c-flex>
              <c-text fontSize="xs" fontWeight="600" mr="2">
                {{ savedPost.post.user.firstname }}
                {{ savedPost.post.user.lastname }}
              </c-text>
              <c-text fontSize="xs" fontWeight="600" color="gray.400">
                {{ savedPost.post.createdAt | moment('DD MMM YYYY') }}
              </c-text>
            </c-flex>
            <!-- <svg
                    v-chakra="{
                        w: '14px',
                        h: '14px',
                    }"
                    >
                    <use href="@/assets/icons/dots-horizontal.svg#dots"></use>
                    </svg> -->
          </c-flex>
          <c-text
            fontSize="xs"
            color="gray.400"
            my="4"
            class="content"
            v-html="savedPost.post.content"
          ></c-text>
        </c-box>
      </c-box>
    </template>
    <c-flex
      v-if="savedPosts.length > 3"
      borderTopWidth="1px"
      h="60px"
      align="center"
      px="6"
    >
      <c-link
        @click="isDrawerOpen = true"
        color="vc-orange.400"
        fontWeight="600"
        fontSize="sm"
      >
        Show all
      </c-link>
    </c-flex>
    <c-drawer
      :isOpen="isDrawerOpen"
      placement="right"
      :on-close="onDrawerClose"
    >
      <!-- :initialFocusRef="() => $refs.inputInsideModal" -->
      <c-drawer-overlay />
      <c-drawer-content backgroundColor="#f4f6fc" maxWidth="45rem">
        <c-drawer-close-button />
        <c-drawer-header>My Saved Posts</c-drawer-header>

        <c-drawer-body overflowY="scroll">
          <c-stack :spacing="6">
            <c-box v-for="savedPost in savedPosts" :key="savedPost.id">
              <NewsCard
                :ref="'post' + savedPost.id"
                @togglePostSave="onPostSaveToggle"
                :post="savedPost.post"
              />
            </c-box>
          </c-stack>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-box>
</template>

<script>
import NewsCard from './NewsCard.vue';
export default {
  components: { NewsCard },
  props: {
    savedPosts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDrawerOpen: false,
    };
  },
  methods: {
    onDrawerClose() {
      this.isDrawerOpen = false;
    },
    onPostSaveToggle(post) {
      this.$emit('togglePostSave', { ...post });
    },
    onPostClick(savedPost) {
      const { id } = savedPost;
      this.isDrawerOpen = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs['post' + id][0].$el.scrollIntoView();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.content {
  a {
    @apply text-blue-500 font-semibold;
  }
}
</style>
