<template>
  <c-box mb="8">
    <c-flex
      p="6"
      bg="#fff"
      borderRadius="8px"
      boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
      h="100px"
    >
      <c-popover
        v-if="isCompanyAdmin"
        :placement="'bottom'"
        v-slot="{ onClose }"
      >
        <c-popover-trigger>
          <c-flex align="center" mr="6" cursor="pointer">
            <c-avatar
              size="md"
              :name="
                postAs
                  ? activeCompany.name
                  : user.firstname + ' ' + user.lastname
              "
              :src="postAs ? activeCompany.logo : user.profilePhoto"
              borderWidth="0px"
              mr="2"
            />
            <svg
              v-chakra="{
                fill: 'vc-orange.400',
                w: '14px',
                h: '14px',
              }"
            >
              <use href="@/assets/icons/chevron-down.svg#chevron"></use>
            </svg>
          </c-flex>
        </c-popover-trigger>
        <c-popover-content width="max-content">
          <c-popover-body padding="0">
            <c-list>
              <c-list-item>
                <c-pseudo-box
                  alignItems="center"
                  px="5"
                  py="3"
                  display="flex"
                  cursor="pointer"
                  @click="togglePostAs(activeCompany.id, onClose)"
                  :_hover="{
                    bg: 'gray.300',
                  }"
                  v-if="!postAs"
                >
                  <c-text>Post as {{ activeCompany.name }}</c-text>
                </c-pseudo-box>
                <c-pseudo-box
                  alignItems="center"
                  px="5"
                  py="3"
                  display="flex"
                  cursor="pointer"
                  @click="togglePostAs(null, onClose)"
                  :_hover="{
                    bg: 'gray.300',
                  }"
                  v-else
                >
                  <c-text>
                    Post as {{ user.firstname }} {{ user.lastname }}
                  </c-text>
                </c-pseudo-box>
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-flex v-else align="center" mr="6">
        <c-avatar
          size="md"
          :name="user.firstname + ' ' + user.lastname"
          :src="user.profilePhoto"
          borderWidth="0px"
        />
      </c-flex>
      <c-pseudo-box
        alignItems="center"
        bg="gray.200"
        borderRadius="100px"
        px="7"
        flex="1"
        display="flex"
        cursor="pointer"
        @click="openPostModal"
        :_hover="{
          bg: 'gray.300',
        }"
      >
        <c-text fontSize="lg" color="grar.400" fontWeight="500">
          Start a post
        </c-text>
      </c-pseudo-box>
    </c-flex>
    <c-modal size="xl" :is-open="isPostModalOpen" :on-close="closePostModal">
      <c-modal-content ref="content" borderRadius="10px">
        <c-modal-header>Create a post</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-flex
            direction="column"
            w="100%"
            h="200px"
            mb="3"
            borderRadius="10px"
            bg="gray.200"
          >
            <c-box maxH="180px" overflowY="auto">
              <quill-editor
                class="editor"
                ref="textEditor"
                v-model="content"
                @change="onChange"
                :options="editorOptions"
              />
            </c-box>
            <c-flex mt="auto" px="2">
              <input
                @change="onFileUpload"
                ref="fileInput"
                type="file"
                hidden
                name=""
                id=""
                :accept="fileUploadType + '/*'"
              />
              <c-button
                px="0"
                variant-color="blue"
                variant="ghost"
                display="flex"
                alignItems="center"
                justifyContent="center"
                :disabled="isCreatingPost"
                @click="addImage"
              >
                <svg
                  v-chakra="{
                    w: '20px',
                    h: '20px',
                    fill: '#000',
                  }"
                >
                  <use href="@/assets/icons/image.svg#image"></use>
                </svg>
              </c-button>
              <c-button
                px="0"
                variant-color="blue"
                variant="ghost"
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click="addVideo"
              >
                <svg
                  v-chakra="{
                    w: '20px',
                    h: '20px',
                    fill: '#000',
                  }"
                >
                  <use href="@/assets/icons/video.svg#video"></use>
                </svg>
              </c-button>
            </c-flex>
          </c-flex>
          <c-flex my="2">
            <c-box
              borderRadius="6px"
              borderWidth="1px"
              v-for="image in uploadedImages"
              :key="image.id"
              w="90px"
              h="90px"
              overflow="hidden"
              mr="2"
              position="relative"
            >
              <c-flex
                top="0"
                left="0"
                right="0"
                bottom="0"
                align="center"
                zIndex="docked"
                justify="center"
                position="absolute"
                borderRadius="inherit"
                v-if="image.isUploading"
                backgroundImage="linear-gradient(
                    to right top,
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                )"
              >
                <c-spinner color="#fff" thickness="2px" />
              </c-flex>
              <c-flex
                top="0"
                left="0"
                right="0"
                bottom="0"
                align="center"
                zIndex="docked"
                justify="center"
                position="absolute"
                borderRadius="inherit"
                v-if="image.failed"
                backgroundImage="linear-gradient(
                    to right top,
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                )"
              >
                <c-button
                  px="0"
                  variant-color="blue"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  :disabled="isCreatingPost"
                  justifyContent="center"
                  @click="
                    uploadFile({
                      file: image.file,
                      type: image.type,
                      id: image.id,
                    })
                  "
                >
                  <svg
                    v-chakra="{
                      w: '20px',
                      h: '20px',
                      fill: '#fff',
                    }"
                  >
                    <use href="@/assets/icons/icon-upload.svg#upload"></use>
                  </svg>
                </c-button>
              </c-flex>
              <img
                v-chakra="{
                  w: '100%',
                  h: '100%',
                  objectFit: 'cover',
                  objectPosition: 'top center',
                }"
                :src="image.url"
                alt=""
                srcset=""
              />
            </c-box>
          </c-flex>
          <c-flex my="2">
            <c-flex
              mr="2"
              p="2"
              h="90px"
              w="190px"
              borderRadius="6px"
              borderWidth="1px"
              :key="video.id"
              overflow="hidden"
              direction="column"
              align="center"
              textAlign="center"
              v-for="video in uploadedVideos"
              position="relative"
            >
              <c-flex
                top="0"
                left="0"
                right="0"
                bottom="0"
                align="center"
                zIndex="docked"
                justify="center"
                position="absolute"
                borderRadius="inherit"
                v-if="video.isUploading"
                backgroundImage="linear-gradient(
                    to right top,
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                )"
              >
                <c-spinner color="#fff" thickness="2px" />
              </c-flex>
              <c-flex
                top="0"
                left="0"
                right="0"
                bottom="0"
                align="center"
                zIndex="docked"
                justify="center"
                position="absolute"
                borderRadius="inherit"
                v-if="video.failed"
                backgroundImage="linear-gradient(
                    to right top,
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                )"
              >
                <c-button
                  px="0"
                  variant-color="blue"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  @click="
                    uploadFile({
                      file: video.file,
                      type: video.type,
                      id: video.id,
                    })
                  "
                >
                  <svg
                    v-chakra="{
                      w: '20px',
                      h: '20px',
                      fill: '#fff',
                    }"
                  >
                    <use href="@/assets/icons/icon-upload.svg#upload"></use>
                  </svg>
                </c-button>
              </c-flex>
              <svg
                v-chakra="{
                  w: '40px',
                  h: '40px',
                  fill: '#000',
                }"
              >
                <use href="@/assets/icons/video.svg#video"></use>
              </svg>
              <c-text fontSize="sm" fontWeight="600">{{ video.name }}</c-text>
              <!-- <img
                v-chakra="{
                  w: '100%',
                  h: '100%',
                  objectFit: 'cover',
                  objectPosition: 'top center',
                }"
                :src="video.name"
                alt=""
                srcset=""
              /> -->
            </c-flex>
          </c-flex>
        </c-modal-body>
        <c-modal-footer pt="0">
          <c-button
            :disabled="isContentEmpty || isCreatingPost"
            variant-color="vc-orange"
            variant="outline"
            mr="3"
            @click="onPostClick"
          >
            Post
            <c-spinner
              v-if="isCreatingPost"
              color="vc-orange.400"
              thickness="2px"
            />
          </c-button>
          <!-- <c-button @click="closePostModal">Cancel</c-button> -->
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import { Quill } from 'vue-quill-editor';

import 'quill-mention/dist/quill.mention.min.css';

import 'quill-mention';

import '@/helpers/editor/blots/mention';

import {
  createPost,
  getMatchingTags,
  getMatchingUsers,
} from '@/services/insight';

import { mapState, mapGetters } from 'vuex';

import { uploadFile } from '@/services/common';
import { generateID } from '@/helpers/data';

export default {
  data() {
    return {
      content: '',
      isPostModalOpen: false,
      editorOptions: {
        placeholder: `What do you want to talk about?`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
      },
      fileUploadType: 'image',
      uploadedImages: [],
      uploadedVideos: [],
      isContentEmpty: true,
      isCreatingPost: false,
      postAs: null,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyAdmin']),
    editor() {
      return this.$refs.textEditor.quill;
    },
  },
  methods: {
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      const textBeforeCursorPos = this.editor.getText(
        startPos,
        cursorPosition - startPos
      );
      return textBeforeCursorPos;
    },
    onChange() {
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    togglePostAs(as, closePopover) {
      closePopover();
      this.postAs = as;
    },
    openPostModal() {
      this.isPostModalOpen = true;
    },
    closePostModal() {
      this.isPostModalOpen = false;
      this.uploadedImages = [];
      this.uploadedVideos = [];
      this.content = '';
    },
    addImage() {
      this.fileUploadType = 'image';
      this.triggerFileUpload();
    },
    addVideo() {
      this.fileUploadType = 'video';
      this.triggerFileUpload();
    },
    triggerFileUpload() {
      this.$nextTick().then(() => {
        this.$refs.fileInput.click();
      });
    },
    uploadFile({ file, type, id }) {
      let fileObject = {};
      var indexOfFile = undefined;
      if (type == 'image') {
        fileObject = this.uploadedImages.find((img) => img.id === id);
        indexOfFile = this.uploadedImages.indexOf(fileObject);
      } else {
        fileObject = this.uploadedVideos.find((video) => video.id === id);
        indexOfFile = this.uploadedVideos.indexOf(fileObject);
      }
      fileObject.failed = false;
      fileObject.isUploading = true;
      uploadFile({ file, type })
        .then((res) => {
          fileObject.isUploading = false;
          fileObject.url = res.data.url;
          fileObject.uploaded = true;
          if (type == 'image') {
            this.uploadedImages.splice(indexOfFile, 1, fileObject);
          } else {
            this.uploadedVideos.splice(indexOfFile, 1, fileObject);
          }
        })
        .catch(() => {
          fileObject.isUploading = false;
          fileObject.failed = true;
          fileObject.file = file;
          if (type == 'image') {
            this.uploadedImages.splice(indexOfFile, 1, fileObject);
          } else {
            this.uploadedVideos.splice(indexOfFile, 1, fileObject);
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while uploading file, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    async onFileUpload(e) {
      const file = e.target.files[0];
      const id = generateID(4);
      if (this.fileUploadType === 'image') {
        this.uploadedImages.push({
          id,
          failed: false,
          type: 'image',
          uploaded: false,
          url: await this.blobToData(file),
        });
      } else {
        this.uploadedVideos.push({
          id,
          type: 'video',
          failed: false,
          name: file.name,
          uploaded: false,
          url: null,
        });
      }
      this.uploadFile({ file, type: this.fileUploadType, id });
    },
    blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    async onPostClick() {
      this.isCreatingPost = true;
      const data = {
        attachments: this.getAttachments(),
        userId: this.user.id,
        content: this.content,
        post_tags: { data: this.getPostTags() },
        mentions: { data: this.getPostMentions() },
      };
      if (this.postAs) {
        data['companyId'] = this.activeCompany.id;
      }
      try {
        const res = await createPost(data);
        this.$emit('postAdded', res.data.insert_post_one);
        this.isCreatingPost = false;
        // this.closePostModal();
      } catch (e) {
        this.isCreatingPost = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while creating post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    getAttachments() {
      const images = this.uploadedImages
        .filter((img) => img.uploaded)
        .map(({ url, type, id }) => {
          return {
            id,
            url,
            type,
          };
        });
      const videos = this.uploadedVideos
        .filter((video) => video.uploaded)
        .map(({ url, type, id }) => {
          return {
            id,
            url,
            type,
          };
        });
      return [...images, ...videos];
    },
    getPostTags() {
      const tags = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="#"]'
        )
      ).map((el) => el.dataset);

      return tags.map((tag) => {
        if (tag.id) {
          return {
            tagId: tag.id,
          };
        }
        return {
          tag: {
            data: {
              userId: this.user.id,
              name: tag.value,
            },
          },
        };
      });
    },
    getPostMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        return {
          userId: mention.id,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  flex: 1;
  ::v-deep {
    .ql-container {
      border-width: 0px;
      font-size: inherit;
      font-family: inherit;
    }
    .mention {
      @apply font-bold bg-transparent;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}
</style>
