import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=b5b9b06a&scoped=true"
import script from "./NewPost.vue?vue&type=script&lang=js"
export * from "./NewPost.vue?vue&type=script&lang=js"
import style0 from "./NewPost.vue?vue&type=style&index=0&id=b5b9b06a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5b9b06a",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAvatar: require('@chakra-ui/vue').CAvatar, CFlex: require('@chakra-ui/vue').CFlex, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CText: require('@chakra-ui/vue').CText, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CSpinner: require('@chakra-ui/vue').CSpinner, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
