<template>
  <c-box
    p="7"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
  >
    <template v-if="!isFetchingSuggestedTags">
      <c-flex justify="space-between" align="center" mb="7">
        <c-text fontWeight="600" fontSize="lg"> Tags </c-text>
        <!-- <AppSelect
        :options="['All']"
        :value="'All'"
        :indicatorFill="'orange'"
        :color="'dark'"
      /> -->
      </c-flex>
      <c-stack :spacing="2">
        <transition-group name="fade" mode="out-in">
          <c-flex
            v-for="(tag, index) in displayedTags"
            :key="tag.id"
            justify="space-between"
            align="start"
          >
            <c-stack :spacing="1">
              <c-text color="blue.600" fontWeight="600">
                {{ tag.name }}
              </c-text>
              <c-text color="gray.300" fontSize="xs">
                {{ tag.post_tags_aggregate.aggregate.count }} Posts
              </c-text>
            </c-stack>
            <c-link
              @click="onFollowClick(index)"
              color="vc-orange.400"
              fontSize="xs"
              fontWeight="600"
              v-if="!tag.isFollowing"
            >
              Follow
            </c-link>
            <c-text fontSize="xs" fontWeight="600" color="green.400" v-else>
              Following
            </c-text>
          </c-flex>
        </transition-group>
      </c-stack>
    </template>
    <!-- <c-box mt="6">
      <c-link fontWeight="600" fontSize="sm" color="vc-orange.400">
        Show More
      </c-link>
    </c-box> -->
  </c-box>
</template>

<script>
import { mapState } from 'vuex';

import { addFollowTag, getSuggestedTags } from '@/services/insight';
import cloneDeep from 'lodash.clonedeep';
export default {
  data() {
    return {
      displayedTags: [],
      restOfTags: [],
      isFetchingSuggestedTags: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getSuggestedTags();
  },
  methods: {
    getSuggestedTags() {
      this.isFetchingSuggestedTags = true;
      getSuggestedTags(this.user.id).then((res) => {
        this.displayedTags = res.data.tag.slice(0, 5).map((tag) => ({
          ...tag,
          isFollowing: tag.follow_tags.length ? true : false,
        }));
        this.restOfTags = res.data.tag.slice(-5);
        this.isFetchingSuggestedTags = false;
      });
    },
    onFollowClick(tagIndex) {
      const tag = this.displayedTags[tagIndex];
      tag.isFollowing = true;
      const data = {
        userId: this.user.id,
        tagId: tag.id,
      };
      addFollowTag(data).then(() => {
        const replacementTag = this.restOfTags.splice(0, 1)[0];
        this.$nextTick(() => {
          setTimeout(() => {
            this.displayedTags.splice(tagIndex, 1, cloneDeep(replacementTag));
          }, 400);
        });
      });
    },
  },
};
</script>

<style></style>
