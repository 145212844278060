var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"bg":"#fff","borderRadius":"8px","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)"}},[_c('c-flex',{attrs:{"borderBottomWidth":"1px","h":"60px","justify":"space-between","align":"center","px":"6"}},[_c('c-text',{attrs:{"fontWeight":"600","fontSize":"lg"}},[_vm._v(" My Saves ")])],1),(!_vm.savedPosts.length)?_c('c-text',{attrs:{"px":"2","fontSize":"sm","py":"3","fontStyle":"italic"}},[_vm._v(" You don't have any saved posts ")]):_vm._e(),_vm._l((_vm.savedPosts.slice(0, 3)),function(savedPost,index){return [_c('c-box',{key:savedPost.id,attrs:{"borderBottomWidth":index !== _vm.savedPosts.length - 1 ? '1px' : '',"px":"6","py":"4","display":"flex","alignItems":"start","cursor":"pointer"},on:{"click":function($event){return _vm.onPostClick(savedPost)}}},[_c('c-avatar',{attrs:{"size":"md","name":savedPost.post.companyId
            ? savedPost.post.company.name
            : savedPost.post.user.firstname +
              ' ' +
              savedPost.post.user.lastname,"src":savedPost.post.companyId
            ? savedPost.post.company.logo
            : savedPost.post.user.profilePhoto,"borderWidth":"0px","mr":"2"}}),_c('c-box',{attrs:{"flex":"1"}},[_c('c-flex',{attrs:{"justify":"space-between"}},[_c('c-flex',[_c('c-text',{attrs:{"fontSize":"xs","fontWeight":"600","mr":"2"}},[_vm._v(" "+_vm._s(savedPost.post.user.firstname)+" "+_vm._s(savedPost.post.user.lastname)+" ")]),_c('c-text',{attrs:{"fontSize":"xs","fontWeight":"600","color":"gray.400"}},[_vm._v(" "+_vm._s(_vm._f("moment")(savedPost.post.createdAt,'DD MMM YYYY'))+" ")])],1)],1),_c('c-text',{staticClass:"content",attrs:{"fontSize":"xs","color":"gray.400","my":"4"},domProps:{"innerHTML":_vm._s(savedPost.post.content)}})],1)],1)]}),(_vm.savedPosts.length > 3)?_c('c-flex',{attrs:{"borderTopWidth":"1px","h":"60px","align":"center","px":"6"}},[_c('c-link',{attrs:{"color":"vc-orange.400","fontWeight":"600","fontSize":"sm"},on:{"click":function($event){_vm.isDrawerOpen = true}}},[_vm._v(" Show all ")])],1):_vm._e(),_c('c-drawer',{attrs:{"isOpen":_vm.isDrawerOpen,"placement":"right","on-close":_vm.onDrawerClose}},[_c('c-drawer-overlay'),_c('c-drawer-content',{attrs:{"backgroundColor":"#f4f6fc","maxWidth":"45rem"}},[_c('c-drawer-close-button'),_c('c-drawer-header',[_vm._v("My Saved Posts")]),_c('c-drawer-body',{attrs:{"overflowY":"scroll"}},[_c('c-stack',{attrs:{"spacing":6}},_vm._l((_vm.savedPosts),function(savedPost){return _c('c-box',{key:savedPost.id},[_c('NewsCard',{ref:'post' + savedPost.id,refInFor:true,attrs:{"post":savedPost.post},on:{"togglePostSave":_vm.onPostSaveToggle}})],1)}),1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }