import { render, staticRenderFns } from "./SavedPosts.vue?vue&type=template&id=438c04b8"
import script from "./SavedPosts.vue?vue&type=script&lang=js"
export * from "./SavedPosts.vue?vue&type=script&lang=js"
import style0 from "./SavedPosts.vue?vue&type=style&index=0&id=438c04b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CAvatar: require('@chakra-ui/vue').CAvatar, CBox: require('@chakra-ui/vue').CBox, CLink: require('@chakra-ui/vue').CLink, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CStack: require('@chakra-ui/vue').CStack, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
