var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"h":"100%","py":"8","px":{ base: '6', lg: 0 }}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"8"}},[_c('c-flex',{attrs:{"align":"baseline"}},[_c('c-heading',{attrs:{"fontSize":"3xl","fontWeight":"700","mr":"3"}},[_vm._v(" Explore ")])],1),_c('Notification')],1),_c('c-grid',{attrs:{"w":"100%","h":{ base: 'calc(130% - 20px)', lg: 'calc(100% - 70px)' },"template-columns":{
      base: '1fr',
      lg: '70% 1fr',
      md: '1fr',
      sm: '1fr',
      xs: '1fr',
    },"gap":"8"}},[_c('c-stack',{attrs:{"pb":"3","px":"2","spacing":8,"maxHeight":"100%","overflowY":"scroll"},nativeOn:{"scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[_c('NewPost',{on:{"postAdded":_vm.onPostAdded}}),(!_vm.isFetchingPosts && !_vm.posts.length)?_c('c-text',{attrs:{"fontStyle":"italic"}},[_vm._v(" You have no posts on your timeline ")]):_vm._e(),_vm._l((_vm.posts),function(post){return [_c('c-box',{key:post.id},[_c('NewsCard',{attrs:{"post":post},on:{"togglePostSave":_vm.onPostSaveToggle,"selectPostAttachment":function($event){_vm.selectedPost = $event;
              _vm.isImagePreviewOpen = true;},"deletePost":function($event){return _vm.onDeletePost(post)}}})],1)]}),_c('image-preview',{attrs:{"isOpen":_vm.isImagePreviewOpen,"post":_vm.selectedPost,"attachments":_vm.selectedPost.attachments.map((attachment) => attachment.url)},on:{"setIsOpen":function($event){_vm.isImagePreviewOpen = $event}}}),_c('c-flex',{attrs:{"justify":"center"}},[(_vm.isFetchingPosts || _vm.isFetchingMorePosts)?_c('c-spinner',{attrs:{"color":"blue.500","thickness":"2px","size":"xl","mx":"auto"}}):_vm._e()],1)],2),_c('c-stack',{attrs:{"spacing":4,"pb":"3"}},[_c('c-box',[_c('SuggestTags')],1),_c('SavedPosts',{attrs:{"savedPosts":_vm.savedPosts},on:{"togglePostSave":_vm.onPostSaveToggle}})],1)],1),_c('c-alert-dialog',{attrs:{"is-open":_vm.isDeleteDialogOpen,"least-destructive-ref":_vm.$refs.cancelRef,"on-close":_vm.closeDeleteDialog}},[_c('c-alert-dialog-overlay'),_c('c-alert-dialog-content',[_c('c-alert-dialog-header',{attrs:{"font-size":"lg","font-weight":"bold"}},[_vm._v(" Delete Post ")]),_c('c-alert-dialog-body',[_vm._v(" Are you sure? You can't undo this action afterwards. ")]),_c('c-alert-dialog-footer',[_c('c-button',{ref:"cancelRef",on:{"click":_vm.closeDeleteDialog}},[_vm._v(" Cancel ")]),_c('c-button',{attrs:{"variantColor":"red","ml":"3"},on:{"click":_vm.deletePost}},[_vm._v(" Delete "),(_vm.isDeletingPost)?_c('c-spinner',{attrs:{"ml":"3","color":"#fff","thickness":"2px"}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }